@keyframes fly-in {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    1% {
        transform: translateY(20em);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
