.chapter-overview {
    background: color('primary');
    min-height: 100vh;

    &[data-columns]::before {
        display: none;

        // the salvattore masonry plugin needs this detailed breakpoints to work correctly
        // http://salvattore.com
        @include breakpoint(mobile down) {
            content: '1 .column--salvattore.column--1';
        }

        @include breakpoint(medium only) {
            content: '2 .column--salvattore.column--2';
        }

        @include breakpoint(large) {
            content: '3 .column--salvattore.column--3';
        }
    }

    @each $theme-name, $theme-colors in $themes {
        .theme--#{$theme-name} & {
            background: background('primary', $theme-name);
        }
    }

    .chapter-preview {
        visibility: hidden;

        &__header {
            &__heading {
                text-indent: 110px;
                margin-top: 7px;
            }
        }

        &__badge {
            position: absolute;
            top: -50px;
            width: 109px;
        }
    }

    .column--salvattore > .chapter-preview {
        opacity: 0;

        &.is-visible {
            animation: fly-in;
            animation-duration: $animation-duration * 5;
            animation-fill-mode: forwards;
            visibility: visible;
        }

        &.is-fixed {
            animation: none;
            visibility: visible;
            opacity: 1;
            margin-top: 0;
        }
    }

    .column--salvattore:nth-child(1) > .chapter-preview {
        animation-delay: $animation-duration;
    }

    .column--salvattore:nth-child(2) > .chapter-preview {
        animation-delay: 0.2 + $animation-duration;
    }

    .column--salvattore:nth-child(3) > .chapter-preview {
        animation-delay: 0.4 + $animation-duration;
    }
}

.chapter-preview {
    display: block;
    background-size: cover;

    &--bg {
        padding-bottom: $spacing * 4;
    }

    &--height {
        &--big {
            padding-bottom: $spacing * 6;
        }

        &--bigger {
            padding-bottom: $spacing * 8;
        }

        &--biggest {
            padding-bottom: $spacing * 10;
        }
    }

    &__intro {
        padding: $spacing ($spacing * 1.5);

        &,
        h1,
        hr {
            border-color: $white;
            color: $white;
        }

        @include on-background('primary-lighter2') {
            &,
            h1,
            hr {
                border-color: $font-color;
                color: $font-color;
            }
        }


        h1 {
            margin-top: 0;
        }

        p {
            margin: ($spacing * 1.5) 0 0;
        }
    }
}

// classes used by salvattore
.column--salvattore {
    float: left;

    &.column {
        &--1 {
            width: percentage(1 / 1);
        }

        &--2 {
            width: percentage(1 / 2);
        }

        &--3 {
            width: percentage(1 / 3);
        }
    }
}
