$input-file-max-height: 100px;

// a default solution (hack) to style file inputs because there is no nice solution which
// works in all browsers, you have to use the provided HTML for this solution to work
.input--file {
    position: relative;
    overflow: hidden;

    input[type="file"] {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        background: transparent;
        cursor: inherit;
        min-width: 100%;
        min-height: 100%;
        text-align: right;
        font-size: $input-file-max-height;
    }

    input[type="text"] {
        background-color: $input-background;
    }
}
