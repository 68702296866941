.content-wrapper {
    margin-top: -1px; // prevent possible white 1px spacers
}

.content__intro {
    @include break-words;

    flex-grow: 1;

    &__headline {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: $spacing;
    }

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-size: cover;
    }

    &__headline,
    &__bg {
        cursor: default;
    }

    &--bg &__headline {
        color: color('white');

        .bg--is-light &.bg--primary-opacity {
            color: color('primary');
        }
    }
}

.content__text {
    display: flex;
    flex-direction: column;
    padding: $spacing * 2;
    height: 100%;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    table tbody tr {
        &:nth-child(even) { background-color: transparentize(background('primary-lighter2'), 0.5); }
        &:nth-child(odd) { background-color: background('light'); }
    }

    // selector depth is required in order to overwrite foundation settings
    // scss-lint:disable SelectorDepth
    .bg--is-dark & {
        table tbody tr {
            &:nth-child(even) { background-color: transparentize(background('primary-dark'), 0.2); }
            &:nth-child(odd) { background-color: transparentize(background('primary-dark'), 0.1); }
        }
    }

    @each $theme-name, $theme-colors in $themes {
        .theme--#{$theme-name} & {
            table tbody tr {
                &:nth-child(even) { background-color: transparentize(background('primary-lighter2', $theme-name), 0.5); }
                &:nth-child(odd) { background-color: background('light', $theme-name); }
            }
        }

        .theme--#{$theme-name} .bg--is-dark & {
            table tbody tr {
                &:nth-child(even) { background-color: transparentize(background('primary-dark', $theme-name), 0.2); }
                &:nth-child(odd) { background-color: transparentize(background('primary-dark', $theme-name), 0.1); }
            }
        }
    }
    // scss-lint:enable
}

.content__image {
    overflow: hidden; // this is required to force IE11 to correctly display the image inside flexboxes

    &--bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-size: cover;

        @include breakpoint(medium down) {
            @include fluid(40%);
        }
    }

    img {
        cursor: pointer;
        width: 100%;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        opacity: 0;
        border-radius: 50%;
        background-color: background('main-opacity');
        padding: 0.625em;
        pointer-events: none;
        color: $white;

        &::before {
            @include fluid(100%); // make the icon a square

            line-height: $line-height-reset;
            font-size: 1.25em;
        }
    }

    @include hover {
        .icon {
            transition: opacity $animation-duration;
            opacity: 1;
        }
    }
}

// headlines used as anchors need some offset to appear correctly on the visible part of the page
h1,
h2,
h3,
h4,
h5,
h6 {
    &[id] {
        @media screen {
            padding-top: $copy-anchor-offset;

            &,
            .content-band__copy &:first-child {
                margin-top: -$copy-anchor-offset;
            }

            &.content__intro__headline {
                padding-top: calc(#{$headline-anchor-offset} + #{$spacing});

                &,
                .content-band__copy &:first-child {
                    margin-top: -$headline-anchor-offset;
                }
            }
        }
    }
}

.content-band[id] {
    @media screen {
        margin-top: -$headline-anchor-offset;
        padding-top: $headline-anchor-offset;
    }
}

.authors {
    text-align: right;
}
