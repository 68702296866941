.footer--wrapper {
    // prevent possible white 1px spacers
    position: relative;
    top: -1px;
    margin-bottom: -1px;
}

.footer {
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 8;

    &,
    a,
    .logo {
        color: $white;
    }

    a {
        @include hover {
            color: color('primary-light');
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 600px;

        @include breakpoint(medium) {
            display: block;
            text-align: center;
        }

        @media print {
            text-align: center;
        }
    }

    &__logo-container {
        display: inline-block;
        margin: $spacing 0;
        vertical-align: 33%;

        @include breakpoint(medium) {
            margin: 0;
        }
    }

    &__spinnwerk-claim {
        display: block;
        margin: $spacing 0;
    }

    &__spinnwerk-claim,
    .menu ul {
        text-align: center;
    }

    .logo {
        display: inline-block;
        width: 150px;
        height: 80px;
        vertical-align: middle;

        @media print {
            &::after {
                display: none;
            }
        }
    }

    .menu {
        text-align: center;

        @include breakpoint(medium) {
            text-align: left;
        }
    }
}
