.sub-nav {
    position: relative;

    @each $theme-name, $theme-colors in $themes {
        &.theme--#{$theme-name} {
            background: background('primary-dark', $theme-name);
        }
    }

    // top shadow
    nav {
        position: relative;

        &::before {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
            background-color: transparentize($black, 0.9);
            height: $sub-nav-shadow-height;
            content: ' ';
        }
    }

    a {
        @include hover($parent: true) {
            color: $white;
        }
    }

    // no default ul styles
    ul {
        display: flex;
        align-content: stretch;
        margin: 0;
        padding: 0;
        list-style-type: none;

        &::before {
            display: none;
        }

        li {
            min-width: $sub-nav-height;

            &.current-menu-item a {
                font-weight: bold;
            }
        }
    }

    &__block {
        flex-grow: 1;

        @include breakpoint(mobile) {
            flex-grow: 0;
        }

        a {
            display: block;
            position: relative;
            transition: background-color $animation-duration $animation-timing;
            background-color: transparent;
            width: 100%;
            height: $sub-nav-height;
            text-align: center;

            @include breakpoint(mobile) {
                margin: 0 auto;
                width: $sub-nav-height;
            }
        }

        a,
        button {
            @include hover() {
                background-color: transparentize($black, 0.635);
            }
        }

        .icon {
            display: block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        a[data-disabled] {
            opacity: 0.5;
        }

        &--scroll-previous,
        &--scroll-next {
            a {
                background-color: transparentize($black, 0.7);
            }
        }

        &--scroll-previous {
            a {
                cursor: w-resize;
            }
        }

        &--scroll-next {
            a {
                cursor: e-resize;
            }
        }
    }

    &__scroll {
        display: none;
        position: relative;
        flex-grow: 2;
        align-items: center;
        justify-content: center;
        background-color: transparentize($black, 0.7);
        overflow: hidden;

        @include breakpoint(mobile) {
            display: flex;
        }

        ul {
            display: block;
            position: relative;
            height: 100%;
            overflow: hidden;
            white-space: nowrap;

            [data-mobile="true"] &,
            [data-tablet="true"] & {
                overflow: auto;
            }

            li {
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                transition: background-color $animation-duration $animation-timing;
                background-color: transparent;
                padding: 0 $spacing;
                height: $sub-nav-height;

                @include hover {
                    background-color: transparentize($black, 0.75);
                }

                &[data-current] {
                    background-color: transparentize($black, 0.75);
                }
            }
        }
    }

    &__scroll,
    &__block {
        a {
            padding-top: $sub-nav-shadow-height / 2;
        }
    }
}

.menu-toggle {
    width: 100%;

    @include hover {
        outline: 0;
    }

    .hamburger-box {
        display: block;
        margin: 0 auto;
    }
}
