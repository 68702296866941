.language-switcher {
    .btn {
        margin: 0;
        border: 0;
        background-color: transparent;
        color: color('primary');
    }

    li {
        border-bottom: $border;

        &:last-child {
            border-bottom: 0;
        }
    }

    a {
        text-decoration: none;
        white-space: nowrap;

        &[disabled] {
            pointer-events: none;
            cursor: default;
            color: color('medium-gray');
        }
    }
}
