.scroll-up {
    @include zindex(overlay, 1);

    display: none;
    position: fixed;
    right: $spacing / 2;
    bottom: 0;
    transition: margin-left $animation-duration, margin-right $animation-duration;
    opacity: 0.6;
    width: 6em;
    height: 3.93em;

    @include breakpoint(mobile down) {
        // classes are set by Foundation, no distinct class possible
        // scss-lint:disable ChainedClasses
        .is-off-canvas-open.is-open-right & {
            margin-right: $offcanvas-size;
        }

        .is-off-canvas-open.is-open-left & {
            margin-left: $offcanvas-size;
        }
        // scss-lint:enable
    }
}
