// disable the extend linting because foundation doesn't support this
// scss-lint:disable PlaceholderInExtend
// also enable the use of the .btn class
.btn {
    @extend .button;
}
// scss-lint:enable PlaceholderInExtend

// override the not wanted browser defaults set in normalize
button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: none;
}

.btn,
.button {
    .icon + .label,
    .label + .icon {
        margin-left: 1em;
    }

    .icon,
    .label {
        vertical-align: middle;
    }

    use {
        fill: $button-color;
    }

    &__preloader {
        display: none;
        margin-left: 1em;
        width: 25px;

        .is-loading & {
            display: inline-block;
        }

        .preloader {
            top: -2px;
        }
    }
}
