.table-scroll {
    &-indicators {
        position: relative;
        margin: 0 $spacing * -2;
        padding: 0 $spacing * 2;

        &__indicator {
            display: none;
            position: absolute;
            top: 0;
            width: $spacing * 4;
            height: 100%;

            &--left {
                left: 0;
                background: linear-gradient(to right, color('primary-lighter3') 50%, transparentize(color('primary-lighter3'), 1));

                .bg--is-light & {
                    background: linear-gradient(to right, color('white') 50%, transparentize(color('white'), 1));
                }
            }

            &--right {
                right: 0;
                background: linear-gradient(to left, color('primary-lighter3') 50%, transparentize(color('primary-lighter3'), 1));

                .bg--is-light & {
                    background: linear-gradient(to left, color('white') 50%, transparentize(color('white'), 1));
                }
            }

            &.is-visible {
                display: block;
            }
        }
    }

    table {
        margin: 0;
    }
}

@each $theme-name, $theme-colors in $themes {
    .theme--#{$theme-name} {
        .table-scroll-indicators__indicator--left {
            background: linear-gradient(to right, color('primary-lighter3', $theme-name) 50%, transparentize(color('primary-lighter3', $theme-name), 1));
        }

        .table-scroll-indicators__indicator--right {
            background: linear-gradient(to left, color('primary-lighter3', $theme-name) 50%, transparentize(color('primary-lighter3', $theme-name), 1));
        }

        .bg--is-light {
            .table-scroll-indicators__indicator--left {
                background: linear-gradient(to right, color('white') 50%, transparentize(color('white'), 1));
            }

            .table-scroll-indicators__indicator--right {
                background: linear-gradient(to left, color('white') 50%, transparentize(color('white'), 1));
            }
        }

        .bg--is-dark,
        .bg--primary-dark {
            .table-scroll-indicators__indicator--left {
                background: linear-gradient(to right, color('primary-dark', $theme-name) 50%, transparentize(color('primary-dark', $theme-name), 1));
            }

            .table-scroll-indicators__indicator--right {
                background: linear-gradient(to left, color('primary-dark', $theme-name) 50%, transparentize(color('primary-dark', $theme-name), 1));
            }
        }

        .bg--primary-light {
            .table-scroll-indicators__indicator--left {
                background: linear-gradient(to right, color('primary-light', $theme-name) 50%, transparentize(color('primary-light', $theme-name), 1));
            }

            .table-scroll-indicators__indicator--right {
                background: linear-gradient(to left, color('primary-light', $theme-name) 50%, transparentize(color('primary-light', $theme-name), 1));
            }
        }
    }
}
