// 1. apply a natural box layout model to all elements, but allowing components to change
//    http://www.paulirish.com/2012/box-sizing-border-box-ftw
// 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//    navigating between pages that do/do not have enough content to produce
//    scrollbars naturally.
// 3. Ensure the page always fills at least the entire height of the viewport.
html {
    box-sizing: border-box; // [1]
    overflow-y: scroll;     // [2]
    min-height: 100%;       // [3]
}

input[type=search], // ovverride normalize search input style
*,
*::before,
*::after {
    box-sizing: inherit; // [1]
}

// 1. prevent possible cross browser z-index bugs
// 2. define a minimal with for very small devices
body {
    @include clearfix;
    @include zindex(base); // [1]
    min-width: $min-width; // [2]
}

// generate our available background classes
@include backgrounds-generate;
