.glossary {
    a {
        border: 0;
    }

    .current {
        display: inline-block;
        background-color: $highlight-color;
    }
}
