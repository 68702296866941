// object fit polyfill for IE
.compat-object-fit {
    display: block;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;

    img {
        @extend %visuallyhidden;
    }
}
