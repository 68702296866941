// settings for the hamburgers

$hamburger-padding-x: 0;
$hamburger-padding-y: 18px;
$hamburger-layer-width: 18px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: color('white');
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: $animation-duration;
$hamburger-hover-transition-timing-function: linear;

$hamburger-types: (squeeze);
