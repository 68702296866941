// style checkoxes with SVGs when the browser has support for them
// the checked symbol is hidden/showen via CSS and the `currentColor` attribute on the SVG element
// this way you can show/hide the element by changing the color attribute of the SVG via CSS to transparent or a color value (#000)

// hide SVGs on devices which don't support SVGs
.label--checkbox,
.label--radio {
    position: relative;
    padding-left: $form-check-width * 1.5;

    input,
    svg {
        position: absolute;
        margin-left: ($form-check-width * 1.5) * -1;
    }

    svg {
        display: none;
    }
}

// we can use SVGs in our browser => style all the things
html.svg {
    .label--checkbox,
    .label--radio {
        // hide the default input
        input {
            // don't user display: none, with this method you disable the elements accessibility
            position: absolute;
            margin: -1px;
            border: 0;
            padding: 0;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0 0 0 0);

            // round the border of radio buttons for nice focus styling
            &[type="radio"] ~ svg {
                border-radius: 50%;
            }

            // hide the checked symbol via the `currentColor` attribute
            ~ svg {
                color: transparent;
            }

            // show the checked symbol when the input is :checked
            &:checked ~ svg {
                color: $form-checked-color;
            }

            // disable the inputs
            &[disabled] ~ svg {
                opacity: 0.5;
            }
        }

        // display the svg in a relative dimension to the label
        svg {
            display: inline-block;
            width: $form-check-width;
            height: $form-check-height;
        }
    }
}
