@mixin video-title {
    .video-title {
        text-transform: uppercase;
        font-size: inherit;
        font-weight: bold;
    }
}

.content-band {
    position: relative;

    &--media {
        display: block;

        @include breakpoint(large) {
            display: flex;
            flex-direction: row;
        }

        &--video {

            @include video-title;

            @media print {
                // print styles must be overruling
                // scss-lint:disable ImportantRule
                display: none !important;
                // scss-lint:enable
            }
        }
    }

    &--flex {
        display: block;

        @include breakpoint(large) {
            display: flex;
        }

        .content-band__intro {
            position: relative;
        }
    }

    @media print {
        border-bottom: 1px solid;
    }
}

.content-band__intro {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;

    &--media {
        position: relative;
        background: color('white');

        img {
            width: 100%;
        }

        &--video {
            @include fluid();
            @include video-title;

            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }

    &--media--lightbox,
    &--on-bg {
        cursor: pointer;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            opacity: 0;
            border-radius: 50%;
            background-color: background('main-opacity');
            padding: 0.625em;
            pointer-events: none;
            color: $white;

            &::before {
                @include fluid(100%); // make the icon a square

                line-height: $line-height-reset;
                font-size: 1.25em;
            }
        }

        @include hover {
            .icon {
                transition: opacity $animation-duration;
                opacity: 1;
            }
        }
    }

    &--on-bg {
        background-position: center;
        background-size: cover;

        &:not(&--with-headline) {
            @include breakpoint(medium down) {
                @include fluid(40%);
            }
        }
    }
}



.content-band__copy {
    position: relative;
    padding: $spacing * 2;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &--columns {
        &--one {
            display: flex;
            flex-direction: column;
            background-color: background('primary-lighter3');

            &.bg--is-dark {
                background-color: background('primary-dark');
            }

            &.bg--is-light {
                background-color: background(light);
            }
        }

        &--two {
            background-color: background('primary-lighter3');

            @include breakpoint(medium) {
                column-gap: $spacing * 4;
                column-count: 2;
            }

            &.bg--is-dark {
                background-color: background('primary-dark');
            }

            &.bg--is-light {
                background-color: background(light);
            }
        }

        &--three {
            background-color: background('primary-lighter3');

            @include breakpoint(medium) {
                column-gap: $spacing * 4;
                column-count: 2;
            }

            @include breakpoint(large) {
                column-gap: $spacing * 4;
                column-count: 3;
            }

            &.bg--is-dark {
                background-color: background('primary-dark');
            }

            &.bg--is-light {
                background-color: background(light);
            }
        }

        // if we also have an intro, we need to start with the second color
        .content-band__intro-with-background-color + & {
            &--one {
                background-color: background('primary-lighter1');

                &.bg--is-dark {
                    background-color: background('primary');
                }

                &.bg--is-light {
                    background-color: background('primary-lighter3');
                }
            }

            &--two {
                background-color: background('primary-lighter1');

                &.bg--is-dark {
                    background-color: background('primary');
                }

                &.bg--is-light {
                    background-color: background('primary-lighter3');
                }
            }

            &--three {
                background-color: background('primary-lighter1');

                &.bg--is-dark {
                    background-color: background('primary');
                }

                &.bg--is-light {
                    background-color: background('primary-lighter3');
                }
            }
        }
    }

    &--headlined-media {
        display: flex;
        flex-direction: column;

        &.content-band__copy--media {
            &-left {
                background-color: background('primary-lighter2');
            }

            &-right {
                background-color: background('primary-lighter1');
            }
        }
    }

    table {
        min-width: 100%;
        border-spacing: 2px;

        img {
            max-width: 250px;
            height: auto;
        }

        td {
            vertical-align: top;
            text-align: center;
        }

        tbody tr {
            &:nth-child(even) {
                background-color: background('primary-lighter2');
            }

            &:nth-child(odd) {
                background-color: background('light');
            }
        }
    }

    &.bg--is-dark {
        table tbody tr {
            &:nth-child(even) {
                background-color: background('primary-lighter1');
                color: $font-color;
            }

            &:nth-child(odd) {
                background-color: background('primary');

                // selector-depth is required to correctly overwrite foundation-styles
                // scss-lint:disable SelectorDepth
                &,
                a {
                    color: color('white');
                }
                // scss-lint:enable
            }
        }
    }

    &.bg--is-light {
        table tbody tr {
            &:nth-child(even) {
                background-color: background('primary-lighter2');
            }

            &:nth-child(odd) {
                background-color: background('primary-lighter3');
            }
        }
    }
}

@each $theme-name, $theme-colors in $themes {
    .theme--#{$theme-name} .content-band__copy {
        // overwrite foundation selectors
        table tbody tr {
            &:nth-child(even) {
                background-color: background('primary-lighter2', $theme-name);
            }

            &:nth-child(odd) {
                background-color: background('light', $theme-name);
            }
        }
    }

    .theme--#{$theme-name} .content-band__copy--headlined-media,
    .theme--#{$theme-name}.content-band__copy--headlined-media {
        &.content-band__copy--media {
            &-left { background-color: background('primary-lighter2', $theme-name); }
            &-right { background-color: background('primary-lighter1', $theme-name); }
        }
    }

    .theme--#{$theme-name} .content-band__copy--columns--one,
    .theme--#{$theme-name}.content-band__copy--columns--one {
        background-color: background('primary-lighter3', $theme-name);
    }

    .theme--#{$theme-name} .content-band__copy--columns--two,
    .theme--#{$theme-name}.content-band__copy--columns--two {
        background-color: background('primary-lighter3', $theme-name);
    }

    .theme--#{$theme-name} .content-band__copy--columns--three,
    .theme--#{$theme-name}.content-band__copy--columns--three {
        background-color: background('primary-lighter3', $theme-name);
    }

    // dark backgrounds
    .theme--#{$theme-name} .content-band__copy,
    .theme--#{$theme-name}.content-band__copy {
        &.bg--is-dark {
            background-color: background('primary-dark', $theme-name);

            // overwrite foundation selectors
            table tbody tr {
                &:nth-child(even) {
                    background-color: background('primary-lighter1', $theme-name);
                }

                &:nth-child(odd) {
                    background-color: background('primary', $theme-name);
                }
            }
        }
    }

    // light backgrounds
    .theme--#{$theme-name} .content-band__copy,
    .theme--#{$theme-name}.content-band__copy {
        &.bg--is-light {
            background-color: background(light, $theme-name);

            // overwrite foundation selectors
            table tbody tr {
                &:nth-child(even) {
                    background-color: background('primary-lighter2', $theme-name);
                }

                &:nth-child(odd) {
                    background-color: background('primary-lighter3', $theme-name);
                }
            }
        }
    }

    // if we also have an intro, we need to start with the second color
    .theme--#{$theme-name} .content-band__intro-with-background-color + .content-band__copy--columns--one,
    .content-band__intro-with-background-color + .theme--#{$theme-name}.content-band__copy--columns--one {
        background-color: background('primary-lighter1', $theme-name);
    }

    .theme--#{$theme-name} .content-band__intro-with-background-color + .content-band__copy--columns--two,
    .content-band__intro-with-background-color + .theme--#{$theme-name}.content-band__copy--columns--two {
        background: linear-gradient(to right, background('primary-lighter1', $theme-name) 0%, background('primary-lighter1', $theme-name) 50%, background('primary-lighter2', $theme-name) 50.1%, background('primary-lighter2', $theme-name) 100%);
    }

    .theme--#{$theme-name} .content-band__intro-with-background-color + .content-band__copy--columns--three,
    .content-band__intro-with-background-color + .theme--#{$theme-name}.content-band__copy--columns--three {
        background: linear-gradient(to right, background('primary-lighter1', $theme-name) 0%, background('primary-lighter1', $theme-name) 33.33%, background('primary-lighter2', $theme-name) 33.4%, background('primary-lighter2', $theme-name) 66.66%, background('primary-lighter3', $theme-name) 66.7%, background('primary-lighter3', $theme-name) 100%);
    }


    // dark backgrounds
    .theme--#{$theme-name} .content-band__copy--headlined-media.bg--is-dark,
    .theme--#{$theme-name}.content-band__copy--headlined-media.bg--is-dark {
        &.content-band__copy--media {
            &-left { background-color: background('primary', $theme-name); }
            &-right { background-color: background('primary-dark', $theme-name); }
        }
    }

    .theme--#{$theme-name} .content-band__intro-with-background-color + .content-band__copy--columns--one.bg--is-dark,
    .content-band__intro-with-background-color + .theme--#{$theme-name}.content-band__copy--columns--one.bg--is-dark {
        background-color: background('primary', $theme-name);
    }

    // light backgrounds
    .theme--#{$theme-name} .content-band__intro--on-bg .content-band__intro__headline.bg--light-opacity {
        color: color('primary', $theme-name);
    }


    .theme--#{$theme-name} .content-band__copy--headlined-media.bg--is-light,
    .theme--#{$theme-name}.content-band__copy--headlined-media.bg--is-light {
        &.content-band__copy--media {
            &-left { background-color: background('primary-lighter3', $theme-name); }
            &-right { background-color: background('primary-lighter2', $theme-name); }
        }
    }

    .theme--#{$theme-name} .content-band__intro-with-background-color + .content-band__copy--columns--one.bg--is-light,
    .content-band__intro-with-background-color + .theme--#{$theme-name}.content-band__copy--columns--one.bg--is-light {
        background-color: background(light, $theme-name);
    }
}
