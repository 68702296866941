// no margin bottom of sub lists
ul,
ol {
    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
    }
}

dl {
    dt,
    dd {
        margin-left: 0;
        padding-left: 0;
    }

    dt {
        font-weight: bold;
    }

    dd {
        margin-bottom: $spacing;
    }
}

// The list-bare class simply removes any indents and bullet points from lists.
.list--bare,
%list--bare {
    &,
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}


// The list-inline class simply displays a list of items in one line.
.list--inline,
%list--inline {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        display: inline-block;

        &::after {
            padding: $spacing / 4;
            content: '|';
        }

        &:last-child::after {
            display: none;
            content: '';
        }
    }
}

// hide after decorator which should usually add an visual seperator
.list--no-seperator,
%list--no-seperator {
    > li::after {
        display: none;
        content: '';
    }
}
