.search {
    .input-group,
    .btn {
        background: color('main-dark');
    }

    &__input {
        margin: 0;
        padding: $menu-item-padding;
        height: 3.9em;
        line-height: $line-height-reset;
        color: $white;
        font-size: 1em;

        @include hover($parent: true) {
            background: color('main-dark');
        }
    }

    .input-group,
    &__input {
        box-shadow: inset 0 8px 0 0 color('main-dark2');
    }
}

.search-results__title {
    @include sgl-heading(3);

    margin-bottom: 0;

    li:first-child & {
        margin-top: 0;
    }
}
