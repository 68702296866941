// fix weird off canvas design issues when the body is smaller then the viewport
.off-canvas-wrapper,
.off-canvas-wrapper-inner,
.off-canvas-content {
    min-height: 100vh;
}

.off-canvas {
    position: absolute;
    top: 0;
    width: $offcanvas-size;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    &.position-right {
        right: -$offcanvas-size;
    }

    &.position-left {
        left: -$offcanvas-size;
    }

    &.position-right,
    &.position-left {
        @include breakpoint(medium) {
            position: static;
            right: 0;
            left: 0;
            background: $offcanvas-background;
            width: auto;
            height: auto;
        }
    }

    &-wrapper-inner {
        position: relative;
        background: $offcanvas-background;
        overflow: hidden;
    }

    &-content {
        position: relative;
        right: 0;
        left: 0;
        transition: right $animation-duration, left $animation-duration;
        box-shadow: $maincontent-shadow;
        background: $body-background;

        .is-open-right & {
            left: -$offcanvas-size;
        }

        .is-open-left & {
            right: -$offcanvas-size;
        }

        .is-open-right &,
        .is-open-left & {
            @include breakpoint(medium) {
                right: 0;
                left: 0;
            }
        }
    }
}

.menu-toggle {
    @include zindex(overlay, 1);
    @include hide-for(medium);
}

.primary-nav {
    // prevent flickering on loading
    @at-root {
        html.js & { display: none; }
    }

    // basic nav styling
    ul {
        @extend %list--bare;
    }

    li {
        padding-left: 50px;
        height: $sub-nav-height;
        text-transform: uppercase;

        a {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            line-height: $line-height-nav;

            @include hover($parent: true) {
                color: $white;
            }
        }

        // need chained class
        // scss-lint:disable ChainedClasses
        &.menu-item.menu-item-object-book {
            position: relative;
        }
        // scss-lint:enable ChainedClasses

        &.theme--blue,
        &.theme--teal,
        &.theme--green,
        &.theme--lime,
        &.theme--orange,
        &.theme--red,
        &.theme--violet {
            transition: background-color $animation-duration $animation-timing, background-size $animation-duration $animation-timing;
            background-repeat: no-repeat;
            background-position: 0 100%;
            background-size: 50px auto;
        }

        &.theme--blue {
            background-image: url('/assets/img/nav/kapitel1.png');

            @include hover() {
                background-color: background('primary-dark', 'blue');
            }

            @include breakpoint(retina) {
                background-image: url('/assets/img/nav/kapitel1@2x.png');
            }
        }

        &.theme--teal {
            background-image: url('/assets/img/nav/kapitel2.png');

            @include hover() {
                background-color: background('primary-dark', 'teal');
            }

            @include breakpoint(retina) {
                background-image: url('/assets/img/nav/kapitel2@2x.png');
            }
        }

        &.theme--green {
            background-image: url('/assets/img/nav/kapitel3.png');

            @include hover() {
                background-color: background('primary-dark', 'green');
            }

            @include breakpoint(retina) {
                background-image: url('/assets/img/nav/kapitel3@2x.png');
            }
        }

        &.theme--lime {
            background-image: url('/assets/img/nav/kapitel4.png');

            @include hover() {
                background-color: background('primary-dark', 'lime');
            }

            @include breakpoint(retina) {
                background-image: url('/assets/img/nav/kapitel4@2x.png');
            }
        }

        &.theme--orange {
            background-image: url('/assets/img/nav/kapitel5.png');

            @include hover() {
                background-color: background('primary-dark', 'orange');
            }

            @include breakpoint(retina) {
                background-image: url('/assets/img/nav/kapitel5@2x.png');
            }
        }

        &.theme--red {
            background-image: url('/assets/img/nav/kapitel6.png');

            @include hover() {
                background-color: background('primary-dark', 'red');
            }

            @include breakpoint(retina) {
                background-image: url('/assets/img/nav/kapitel6@2x.png');
            }
        }

        &.theme--violet {
            background-image: url('/assets/img/nav/kapitel7.png');

            @include hover() {
                background-color: background('primary-dark', 'violet');
            }

            @include breakpoint(retina) {
                background-image: url('/assets/img/nav/kapitel7@2x.png');
            }
        }
    }

    // default navigation design on big screens
    @include breakpoint(medium) {
        margin: 0;

        li {
            float: left;
            padding-bottom: 50px;
            width: percentage(1 / 7);
            height: 0;
            overflow: hidden;

            &.theme--blue,
            &.theme--teal,
            &.theme--green,
            &.theme--lime,
            &.theme--orange,
            &.theme--red,
            &.theme--violet {
                background-size: auto 80%;

                @include breakpoint(xlarge) {
                    background-size: 30% auto;
                }

                .header--not-top & {
                    @include breakpoint(xlarge) {
                        background-size: 50px auto;
                    }
                }
            }


            a {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: $menu-item-padding;
            }

            .label {
                display: none;
            }

            @include breakpoint(xlarge) {
                padding-bottom: 11%;

                .label {
                    display: inline;
                }
            }

            @include breakpoint(xxlarge) {
                padding-bottom: 9.5%;
            }
        }

        .dropdown {
            > li {
                position: relative;

                &.has-submenu > a {
                    margin-right: 10px;
                }
            }
        }
    }

    // "mobile" navigation
    @include breakpoint(medium down) {
        .is-drilldown .menu > li {
            display: block;
        }

        .is-drilldown-submenu-parent > a {
            display: block;
        }
    }
}
