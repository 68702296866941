label {
    &.has-error {
        color: $alert-color;

        input,
        textarea,
        select {
            box-shadow: inset 0 4px 0 0 $alert-color;
        }

        .errors::before {
            content: ' — ';
        }
    }

    &.is-valid {
        color: $success-color;

        input,
        textarea,
        select {
            box-shadow: inset 0 4px 0 0 $success-color;
        }
    }

    .errors {
        ul {
            @extend %list--bare;
            @extend %list--inline;
            @extend %list--no-seperator;

            display: inline-block;
        }
    }
}


input,
textarea,
select {
    max-width: rem-calc(400);
}

textarea {
    resize: vertical;
}

select {
    display: block;
    width: 100%;
}
