// Colors
// -------
$colors: (
    'black':             #0a0a0a,
    'white':             #fff,
    'main':              #002f55,
    'main-dark':         #001a2f,
    'main-dark2':        #00101d,
    'main-light':        #005b9b,
    'secondary':         #777,
    'success':           #3adb76,
    'warning':           #ffae00,
    'alert':             #ec5840,
    'light-gray':        #e6e6e6,
    'medium-gray':       #cacaca,
    'dark-gray':         #8a8a8a,
    'yellow':            #ff0,
    'xlsx-green':             #257247
);

$themes: (
    'blue': (
        'primary': #005d99,
        'primary-dark': #06446c,
        'primary-light': #4c99f3,
        'primary-lighter1': #d9e8f6,
        'primary-lighter2': #e8f1f9,
        'primary-lighter3': #f1f6fa,
        'primary-lighter4': #f8fbfd
    ),
    'teal': (
        'primary': #15bac6,
        'primary-dark': #0798a3,
        'primary-light': #3adae6,
        'primary-lighter1': #daf0f2,
        'primary-lighter2': #e3f4f6,
        'primary-lighter3': #f1f9fa,
        'primary-lighter4': #f8fcfd
    ),
    'green': (
        'primary': #009e62,
        'primary-dark': #00784a,
        'primary-light': #1ec988,
        'primary-lighter1': #d4f1e6,
        'primary-lighter2': #def4ec,
        'primary-lighter3': #e9f7f2,
        'primary-lighter4': #f4fbf9
    ),
    'lime': (
        'primary': #1ed364,
        'primary-dark': #04b749,
        'primary-light': #49f28a,
        'primary-lighter1': #d5f5e1,
        'primary-lighter2': #ddf8e7,
        'primary-lighter3': #edf9f1,
        'primary-lighter4': #f6fcf8
    ),
    'orange': (
        'primary': #ffa100,
        'primary-dark': #e78000,
        'primary-light': #febf41,
        'primary-lighter1': #fcf3de,
        'primary-lighter2': #fbf7ec,
        'primary-lighter3': #fdfaf4,
        'primary-lighter4': #fefdfa
    ),
    'red': (
        'primary': #d53829,
        'primary-dark': #a40e00,
        'primary-light': #fa5f50,
        'primary-lighter1': #f9eae9,
        'primary-lighter2': #f9efef,
        'primary-lighter3': #fcf7f6,
        'primary-lighter4': #fefbfb
    ),
    'violet': (
        'primary': #9d0050,
        'primary-dark': #620032,
        'primary-light': #d03383,
        'primary-lighter1': #f5eaf0,
        'primary-lighter2': #f8eff3,
        'primary-lighter3': #fbf3f7,
        'primary-lighter4': #fdf9fb
    )
);


// function to easily access colors and also validate the input
@function color($col, $theme: 'blue') {
    $theme-colors: map-merge($colors, map-get($themes, $theme));

    @if map-has-key($theme-colors, $col) {
        @return map-get($theme-colors, $col);
    } @else {
        @error 'The given color "#{$col}" does not exist';
    }
}

// font colors
$font-color:          color('main');
$font-color-light:    lighten($font-color, 20);
$font-color-header:   $font-color;

// border colors
$border-color: color('main');

// misc
$highlight-color: color('yellow');
