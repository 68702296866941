.browser-alert {
    @include zindex('critical');

    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    background-color: $browser-alert-color-bg;
    padding: $spacing / 3;
    text-align: center;
    color: $browser-alert-color;
    font-size: $small-font-size;

    a,
    a:hover,
    a:active,
    a:focus {
        color: $browser-alert-color-link;
    }
}

// don't display activate cookies if they are activated
html.no-cookies .browser-alert--activate-cookies {
    display: block;
}

// style our browser alert close icon
.browser-alert__close {
    position: absolute;
    top: 0.35em;
    right: 0.8em;
    font-size: $small-font-size;
}
