// 1. Prevent certain mobile browsers from automatically zooming fonts.
// 2. Fonts on OSX will look more consistent with other systems that do not
//    render text using sub-pixel anti-aliasing.
html {
    @include font-default;
    text-size-adjust: 100%; // [1]
    -moz-osx-font-smoothing: grayscale;  // [2]
    -webkit-font-smoothing: antialiased; // [2]
    font-variant-ligatures: normal;
    color: $font-color;

    // define our min font size
    @include sgl-body();

    // scale up our font size when the viewport gets larger
    font-size: calc(#{$font-size-min} + (#{strip-units($font-size-max)} - #{strip-units($font-size-min)}) * ((100vw - #{$min-width}) / (#{strip-units($max-width)} - #{strip-units($min-width)})));

    // when we reached our max breakpoint stop scaling up our font size
    @media (min-width: $max-width) {
        @include sgl-body();
    }
}

// set margin bottom on bottom for vertical rythm
p,
blockquote,
pre,
address,
dl,
ol,
ul,
table {
    @include sgl-margins();
    margin-top: 0;
}


h1 {
    @include sgl-heading(6);
    @include font-headline;
    text-transform: uppercase;
    line-height: $lineheight-header1;
    color: $font-color-header;
    font-weight: bold;
}

h2 {
    @include sgl-heading(5);
}

h3 {
    @include sgl-heading(4);
}

h4 {
    @include sgl-heading(3);
}

h5 {
    @include sgl-heading(2);
}

h6 {
    @include sgl-heading(1);
}

h2,
h3,
h4,
h5,
h6 {
    @include font-headline;
    text-transform: uppercase;
    line-height: $lineheight-header;
    color: $font-color-header;
    font-weight: bold;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    @include font-default;
}

a {
    transition: color $animation-duration $animation-timing, border-color $animation-duration $animation-timing;
    cursor: pointer;
    text-decoration: none;
    color: color('primary');

    @include hover() {
        color: color('primary-light');

        @include on-background('primary-light') {
            color: color('primary-dark');
        }
    }

    &.encyclopedia {
        .source-list &,
        .table-source & {
            border-bottom-color: transparent;
        }
    }
}

// only style inside of paragraph so influence as less as possible else
p,
.boxes__copy,
.content__copy,
.content-band__copy,
.content__text {
    a,
    u,
    ins {
        border-bottom: 1px solid currentColor;
        text-decoration: none;

        @media print {
            border: 0;
        }
    }
}

hr {
    margin: ($spacing / 8) 0;
    margin-bottom: $spacing;
    border: 0;
    border-bottom: $border;

    @media print {
        display: none;
    }
}

a.encyclopedia,
abbr[title] {
    border-bottom: 1px dotted currentColor;
    cursor: help;
    text-decoration: none;

    @media print {
        border: 0;
    }
}

cite {
    color: $font-color-light;
    font-style: italic;

    &::before {
        content: '\2014 \00A0';
    }
}

blockquote {
    border-left: 2px solid $border-color;
    padding-left: $spacing / 2;

    &,
    & cite {
        color: $font-color-light;
    }
}

ul,
ol,
dt {
    padding-left: $spacing * 2;
}

dd {
    padding-left: $spacing * 3;
}



// adjustments to colors inside of themes
@each $theme-name, $theme-colors in $themes {
    // headlines
    .theme--#{$theme-name} h1,
    .theme--#{$theme-name} h2,
    .theme--#{$theme-name} h3,
    .theme--#{$theme-name} h4,
    .theme--#{$theme-name} h5,
    .theme--#{$theme-name} h6,
    .theme--#{$theme-name} .video-title,
    h1.theme--#{$theme-name},
    h2.theme--#{$theme-name},
    h3.theme--#{$theme-name},
    h4.theme--#{$theme-name},
    h5.theme--#{$theme-name},
    h6.theme--#{$theme-name},
    .video-title.theme--#{$theme-name} {
        color: color('primary', $theme-name);
    }

    // a, abbr
    .theme--#{$theme-name} a,
    .theme--#{$theme-name} abbr,
    abbr.theme--#{$theme-name},
    a.theme--#{$theme-name} {
        color: color('primary', $theme-name);

        @include hover() {
            color: color('primary-light', $theme-name);
        }
    }

    .theme--#{$theme-name} .bg--primary-light a,
    .theme--#{$theme-name} .bg--primary-light abbr,
    .bg--primary-light abbr.theme--#{$theme-name},
    .bg--primary-light a.theme--#{$theme-name} {
        @include hover() {
            color: color('primary-dark', $theme-name);
        }
    }

    // a, abbr
    .theme--#{$theme-name} .btn,
    .btn.theme--#{$theme-name} {
        color: color('white');

        @include hover() {
            color: color('white');
        }
    }

    // strong, b
    .theme--#{$theme-name} strong,
    .theme--#{$theme-name} b,
    strong.theme--#{$theme-name},
    b.theme--#{$theme-name} {
        color: color('primary', $theme-name);
    }

    // strong, b
    .theme--#{$theme-name} .bg--is-dark strong,
    .theme--#{$theme-name} .bg--is-dark b,
    .theme--#{$theme-name}.bg--is-dark strong,
    .theme--#{$theme-name}.bg--is-dark b,
    strong.theme--#{$theme-name}.bg--is-dark,
    b.theme--#{$theme-name}.bg--is-dark {
        color: $white;
    }

    // hr
    .theme--#{$theme-name} hr,
    hr.theme--#{$theme-name} {
        border-color: color('primary', $theme-name);
    }
}


// adjustments to colors on dark backgrounds
.bg--is-dark {
    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .video-title {
        color: $white;
    }

    a,
    abbr {
        color: $white;
    }

    hr {
        border-color: $white;
    }
}

.chapter-preview__intro h1 {
    font-size: 2em;
}

.content__intro__headline {
    font-size: 2.3em;
}

.table-source {
    text-align: right;
    font-size: 0.8em;
}

// remove border styling for glossary items in headline
h1,
h2,
h3,
h4,
h5,
h6 {
    a {
        &.encyclopedia {
            border: 0;
        }
    }
}
