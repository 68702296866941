// 1. Make our SVGs behave like they are replaced by svg4everybody and are "inlined"
// 2. Fluid SVGs for responsive purposes.
// 3. Prevent SVGs to get bigger then their containers otherwise they are cut of and not displayed.
// 4. Inherit the parent text color
svg {
    display: inline-block;
    width: 100%; // [1]
    max-width: 100%;  // [2]
    height: auto;
    max-height: 100%; // [3]
    fill: currentColor; // [4]

    > img {
        width: 100%;
        height: auto;
    }

    &:not(:root) {
        overflow: visible; // attempt to fix the cutoff logo in safari
    }
}
