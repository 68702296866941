.header {
    @include clearfix;
    @include zindex(overlay);

    position: relative;
    transition: left $animation-duration;
    background: $body-background;
    width: 100%;

    @include breakpoint(medium) {
        padding-bottom: 0;
    }

    &--headroom {
        position: fixed;
        top: 0;
        left: 0;
    }

    &--top {
        position: absolute;
        transform: translateY(0);
        transition: left $animation-duration;
    }

    &--not-top {
        @include breakpoint(medium) {
            top: 100px;
            transform: translateY(-100%);
        }
    }

    .is-off-canvas-open & {
        @include breakpoint(mobile down) {
            position: fixed;
            top: 0;
            left: -$offcanvas-size; // required for iPhones and the like
            transform: translateY(0);
        }
    }

    .logo {
        display: inline-block;
        height: 100%;

        @include hover($parent: true) {
            color: $white;
        }

        svg {
            display: block;
            max-width: 100px;
        }

        &__container {
            display: block;
            padding: $spacing * 0.2;
            width: auto;
            height: 3.9em;
            text-transform: uppercase;
            color: $white;
        }
    }
}
