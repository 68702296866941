// 1. Fluid images for responsive purposes.
// 2. Setting `vertical-align` removes the whitespace that appears under `img`
//    elements when they are dropped into a page as-is. Safer alternative to
//    using `display: block;`.
// 3. Offset `alt` text from surrounding copy.
img,
picture {
    display: inline-block;
    margin: 0;
    max-width: 100%;        // [1]
    vertical-align: middle; // [2]
    font-style: italic;     // [3]
}



// 1. Google Maps breaks if `max-width: 100%` acts upon it; use their selector
//    to remove the effects.
// 2. If a `width` and/or `height` attribute have been explicitly defined, let’s
//    not make the image fluid.
.gm-style img, // [1]
img[width],    // [2]
img[height] {  // [2]
    max-width: none;
}
