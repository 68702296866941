// Common Vars
// ------------
$null: 0;

// max & min width of your site
// -----------------------------
$max-width: $global-width;
$min-width: 320px;

// Typography
// -----------
$font-size-max: 16px;
$font-size-min: 13px;

// Lineheights
// ------------
$line-height-reset: 1;
$line-height-nav: 1.3;
$lineheight-header: 2rem;
$lineheight-header1: 2.6rem;

// Vertical Rhythm (Sassy-Gridlover)
// ----------------------------------
$sgl-debug-mode: false;
$sgl-base-font-size: strip-units($font-size-max);
$sgl-base-line-height: $global-lineheight;
$sgl-base-unit: 'em';
$sgl-scale-factor: 1.165;

// Spacing
// --------
// use this variable for all your spacings like paddings and
// margins to easily change them via one variable
$spacing: $global-lineheight * 1em;

// use rem here so the offset is independent from any set font-sizes on the corresponding elements
$copy-anchor-offset: (150 / 16) * 1rem;
$headline-anchor-offset: (100 / 16) * 1rem;

// Border
// -------
$border-size:  4px;
$border-style: solid;
$border:       $border-size $border-style $border-color;

// Forms
// ------
$form-border:        $border-size $border-style $border-color;
$form-border-hover:  $border-size $border-style darken($border-color, 10);
$form-border-radius: $global-radius;

$form-icon-size:     2em;

// Checkbox & Radiobutton
// -----------------------
$form-check-width:   rem-calc(24, $font-size-max);
$form-check-height:  rem-calc(24, $font-size-max);
$form-checked-color: #002f55;

// Browser Alerts
// ---------------
$browser-alert-color:      $black;
$browser-alert-color-link: $white;
$browser-alert-color-bg:   $warning-color;

// Animation
// ----------
$animation-duration: 0.3s;
$animation-timing:   ease;

// Preloaders
// -----------
$preloader-color: $black;

// Various
// -----------
$sub-nav-height: 50px;
$sub-nav-shadow-height: 8px;
