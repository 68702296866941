.clearfix {
    @include clearfix;
}

.push-bottom {
    margin-top: auto;
}

.margin-bottom {
    padding-bottom: 12.5em; // use padding in order to also expand its container correctly
}

p,
div {
    &.emphasize {
        color: color('primary');
        font-size: 1.2em;
        font-weight: bold;

        .bg--is-dark & {
            color: color('white');
        }
    }
}

@each $theme-name, $theme-colors in $themes {
    .theme--#{$theme-name} {
        p,
        div {
            &.emphasize {
                color: color('primary', $theme-name);

                .bg--is-dark & {
                    color: color('white');
                }
            }
        }
    }
}

%inline,
.inline {
    display: inline-block;
    float: none;
    width: auto;
}

.center {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.image-replacement {
    border: 0;
    background-color: transparent;
    overflow: hidden;

    &::before {
        display: block;
        width: 0;
        height: 150%;
        content: ' ';
    }
}

%visuallyhidden,
.visuallyhidden {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &.focusable:active,
    &.focusable:focus {
        position: static;
        margin: 0;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
    }
}

.case-sensitiv {
    text-transform: none;
}
