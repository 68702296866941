.boxes {
    display: block;
    position: relative; // required for z-indexes

    @include breakpoint(large) {
        display: flex;
        flex-direction: row;
    }

    &__column {
        display: block;
        position: relative;
        padding: 0;

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        > * {
            height: 100%;
        }
    }
}
