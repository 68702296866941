.gallery {
    margin-bottom: 0;
    padding: 0;

    @media screen {
        // print styles must be overruling
        // scss-lint:disable ImportantRule
        padding-bottom: 0 !important;
        // scss-lint:enable
    }

    &__item {
        width: 100%;
        overflow: visible;

        @include breakpoint(tiny down) {
            // !important is required to overwrite DOM-style-settings for smaller screens
            // scss-lint:disable ImportantRule
            padding-bottom: 0 !important;
            // scss-link:enable
        }

        @media screen and #{breakpoint(mobile)}, print and #{breakpoint(mobile)} {
            @include fluid();
            float: left;
        }


        &__wrapper {
            position: relative;

            @include breakpoint(mobile) {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: -1px;
                overflow: hidden;
            }

            .icon {
                @media print {
                    display: none;
                }
            }
        }

        img {
            cursor: pointer;
            width: 100%;

            @include breakpoint(mobile) {
                @include vertical-center();
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            opacity: 0;
            border-radius: 50%;
            background-color: background('main-opacity');
            padding: 0.625em;
            pointer-events: none;
            color: $white;

            &::before {
                @include fluid(100%);

                line-height: $line-height-reset;
                font-size: 1.25em;
            }
        }

        @include hover {
            .icon {
                transition: opacity $animation-duration;
                opacity: 1;
            }
        }
    }
}

@media screen and #{breakpoint(mobile)}, print and #{breakpoint(mobile)} {
    .gallery {
        &--count-2 &__item {
            width: 50%;
        }

        &--count-3 &__item {
            width: 33.33%;
        }

        &--count-4 &__item {
            width: 25%;
        }

        &--count-5 &__item {
            width: 20%;
        }
    }
}


// fix path of the photoswipe defualt UI image
.pswp__button,
.pswp__button--arrow--left::before,
.pswp__button--arrow--right::before {
    background-image: url('/assets/img/photoswipe/default-skin.png');
}

.pswp--svg .pswp__button,
.pswp--svg .pswp__button--arrow--left::before,
.pswp--svg .pswp__button--arrow--right::before {
    background-image: url('/assets/img/photoswipe/default-skin.svg');
}

.pswp--svg .pswp__button--arrow--left,
.pswp--svg .pswp__button--arrow--right {
    background: none;
}

.pswp__preloader--active .pswp__preloader__icn {
    background-image: url('/assets/img/photoswipe/preloader.gif');
}
