// ///////////////////////////////////////////
// foundation mixins
@import 'node_modules/foundation-sites/scss/global';
@import 'node_modules/foundation-sites/scss/grid/grid',
        'node_modules/foundation-sites/scss/typography/typography',
        'node_modules/foundation-sites/scss/forms/forms',
        'node_modules/foundation-sites/scss/components/visibility',
        'node_modules/foundation-sites/scss/components/float',
        'node_modules/foundation-sites/scss/components/button',
        'node_modules/foundation-sites/scss/components/button-group',
        'node_modules/foundation-sites/scss/components/accordion-menu',
        'node_modules/foundation-sites/scss/components/accordion',
        'node_modules/foundation-sites/scss/components/badge',
        'node_modules/foundation-sites/scss/components/breadcrumbs',
        'node_modules/foundation-sites/scss/components/callout',
        'node_modules/foundation-sites/scss/components/close-button',
        'node_modules/foundation-sites/scss/components/drilldown',
        'node_modules/foundation-sites/scss/components/dropdown-menu',
        'node_modules/foundation-sites/scss/components/dropdown',
        'node_modules/foundation-sites/scss/components/flex-video',
        'node_modules/foundation-sites/scss/components/label',
        'node_modules/foundation-sites/scss/components/media-object',
        'node_modules/foundation-sites/scss/components/menu',
        'node_modules/foundation-sites/scss/components/off-canvas',
        'node_modules/foundation-sites/scss/components/orbit',
        'node_modules/foundation-sites/scss/components/pagination',
        'node_modules/foundation-sites/scss/components/progress-bar',
        'node_modules/foundation-sites/scss/components/reveal',
        'node_modules/foundation-sites/scss/components/slider',
        'node_modules/foundation-sites/scss/components/sticky',
        'node_modules/foundation-sites/scss/components/switch',
        'node_modules/foundation-sites/scss/components/table',
        'node_modules/foundation-sites/scss/components/tabs',
        'node_modules/foundation-sites/scss/components/title-bar',
        'node_modules/foundation-sites/scss/components/top-bar',
        'node_modules/foundation-sites/scss/components/thumbnail',
        'node_modules/foundation-sites/scss/components/tooltip';

// ///////////////////////////////////////////
// foundation custom additions/fixes
// These styles are applied to a <meta> tag, which is read by the Foundation JavaScript
$foundation-breakpoint-font: '#{-zf-bp-serialize($breakpoints)}';

.foundation-mq {
    font-family: $foundation-breakpoint-font;
};

// ///////////////////////////////////////////
// foundation classes
@include foundation-grid;
// @include foundation-global-styles;
// @include foundation-typography;
@include foundation-forms;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-button;
// @include foundation-button-group;
// @include foundation-accordion-menu;
// @include foundation-accordion;
// @include foundation-badge;
@include foundation-breadcrumbs;
// @include foundation-callout;
// @include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;
@include foundation-dropdown;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-off-canvas; note: we are styling the off-canvas ourselves, default styles are incompatible with headroom.js
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-reveal;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-switch;
@include foundation-table;
// @include foundation-tabs;
@include foundation-text-alignment;
// @include foundation-title-bar;
// @include foundation-top-bar;
// @include foundation-thumbnail;
// @include foundation-tooltip;

// @include foundation-flex-classes;
