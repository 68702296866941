.input-group {
    @include clearfix;
    position: relative;
    margin: 0 0 ($spacing / 4);

    &:last-child {
        margin-bottom: 0;
    }
}

// style labels and add some helpers
label .required {
    outline: 0;
    border: 0;
    vertical-align: super;
    text-decoration: none;
    font-size: $small-font-size;
}

.label--inline {
    @extend %inline;
}

.wpcf7-submit {
    font-size: 1rem;
}
