// Typography
// -----------
// If you use custom fonts in your project go and read @spinnwerk/coding-conventions
// Fonts.md => Avoiding the FOIT & Async Loading for infos how this can impact
// the performance and rendering of your site and how this problem is solved.
//
// When you add/remove custom fonts be sure to ajust the FontFaceLoader in your
// critical.js file to reflect your changes. The rest of the logic is handled if
// you stick to the presented solution.

// defines if we should load the custom fonts async or just use them instant
// NOTE: WP caches the html, so the fonts-loaded class isn't handled correctly => always display custom fonts
$force-fonts-loaded: true !default;

// Default font-families, these fonts are displayed before our custom fonts are loaded
// Ensure that this fonts are "save", this means they are natively supported by all browsers
$font-family-default: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-family-header:  $font-family-default;

// These fonts are displayed after they are loaded
// We know they are loaded when our document has a class of "fonts-loaded"
$font-family-default-loaded: 'Gotham Narrow A', 'Gotham Narrow B', $font-family-default;
$font-family-header-loaded:  $font-family-default-loaded;

// /////////////////////////////////////////////////////////////////////////////
// load custom fonts
// @include font-face('Burlington', '/assets/fonts/Burlington/regular/Burlington', $weight: normal, $style: normal, $woff2: false);
// @include font-face('Burlington', '/assets/fonts/Burlington/italic/Burlington', $weight: normal, $style: italic, $woff2: false);
// @include font-face('Burlington', '/assets/fonts/Burlington/bold/Burlington', $weight: bold, $style: normal, $woff2: false);
// @include font-face('Burlington', '/assets/fonts/Burlington/bolditalic/Burlington', $weight: bold, $style: italic, $woff2: false);

// /////////////////////////////////////////////////////////////////////////////
// minins to use this cusom fonts
@mixin font-template($font, $font-loaded) {
    // define "fallback" fonts which have native support of the browsers
    font-family: $font;

    // apply your custom fonts when they are loaded/cached by the browser
    &.fonts-loaded,
    .fonts-loaded & {
        font-family: $font-loaded;
    }

    // ignore fonts loaded if wanted
    @if $force-fonts-loaded {
        font-family: $font-loaded;
    }
}

@mixin font-default {
    @include font-template($font-family-default, $font-family-default-loaded);
}

@mixin font-headline {
    @include font-template($font-family-header, $font-family-header-loaded);
}
