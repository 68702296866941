// prevent image borders on linked images
a > img {
    border: 0;
}

.content-band,
.boxes {
    a[target="_blank"] {
        @include icon('external');

        position: relative;
        margin-left: $spacing - 0.25;

        &::before {
            position: absolute;
            top: 0;
            left: -$spacing;
            font-size: 0.75em;
        }

        @media print {
            margin-left: 0;

            &:not(.link--download)::before {
                display: none;
            }
        }

        &.encyclopedia {
            position: static;
            margin-left: 0;

            &::before {
                display: none;
                content: '';
            }
        }
    }

    .link--download[target='_blank'] {
        @include icon('external', 'before');
    }

    .link--download,
    .link--download[type] {
        @include icon('generic', 'before');
    }

    .link--download[href $='.pdf'],
    .link--download[type='application/pdf'] {
        @include icon('pdf', 'before');
    }

    .link--download[href $='.xlsx'],
    .link--download[type='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] {
        @include icon('xlsx', 'before');

        &::before {
            color: color('xlsx-green');
        }
    }

    @media print {
        a[href ^="mailto:"]::after {
            display: none;
        }
    }

    .link--download[href ^="http://youtube"],
    .link--download[href ^="https://youtube"],
    .link--download[href ^="http://www.youtube"],
    .link--download[href ^="https://www.youtube"] {
        @include icon('youtube', 'before');
    }

    a[target="_blank"],
    .link--download {
        // provide special class to prevent this
        &.no-link-icon {
            margin-left: 0;

            &::before {
                display: none;
            }
        }
    }
}

// style links based on their usage
.link--download {
    .content-band &,
    .boxes & {
        &,
        &[target='_blank'] {
            display: table-cell;
            position: relative;
            margin-left: $spacing;
            border: 0;
            vertical-align: middle;
            font-size: 1em;

            @include hover($parent: true) {
                color: color('main');
            }

            &::before {
                @extend %icon;
                position: absolute;
                top: -0.2em;
                left: -1.3em;
                color: color('primary', 'red');
                font-size: 1.25em;
            }
        }
    }

    // chaining classes is required due to twig
    // scss-lint:disable ChainedClasses
    .content-band .bg--is-dark &,
    .content-band.bg--is-dark &,
    .boxes .bg--is-dark &,
    .boxes.bg--is-dark & {
        color: $white;

        &::before {
            color: $white;
        }
    }
    // scss-lint:enable

    .content-band__copy--columns--one &,
    .content-band__copy--headlined-media &,
    .content__text & {
        &,
        &[target='_blank'] {
            display: inline;
            flex-wrap: wrap;
            align-items: center;
        }
    }
}

// adjustments to colors inside of themes
@each $theme-name, $theme-colors in $themes {
    .theme--#{$theme-name} .encyclopedia,
    .theme--#{$theme-name} abbr,
    .encyclopedia.theme--#{$theme-name},
    abbr.theme--#{$theme-name} {
        border-color: color('primary', $theme-name);
        color: inherit;
    }
}

// adjustments to colors on dark backgrounds
.bg--is-dark {
    .encyclopedia {
        border-color: $white;

        @include hover {
            border-color: inherit;
        }
    }
}

@media print {
    a[href].encyclopedia::after {
        content: ' (' attr(title) ')';
    }
}

.source-list a[target="_blank"] {
    margin-left: 0;

    &::before {
        display: none;
    }
}
