.input-group__icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin-bottom: $global-margin;
    width: $form-icon-size;
    line-height: $null;

    .icon {
        @include vertical-center;
    }
}
