// lodash templated used by SPiNNWERK gulp to generate our icon font output

@font-face {
    font-family: "icon";
    src: url('/assets/icons/icon.eot');
    src: url('/assets/icons/icon.eot?#iefix') format('eot'),
         url('/assets/icons/icon.woff') format('woff'),
         url('/assets/icons/icon.ttf') format('truetype');
}

.icon,
%icon {
    font-family: "icon";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
}

@function icon-char($filename) {
    $char: "";

    @if $filename == arrowleft {
        $char: "\";
    }
    @if $filename == arrowright {
        $char: "\";
    }
    @if $filename == burger {
        $char: "\";
    }
    @if $filename == close {
        $char: "\";
    }
    @if $filename == crossReference {
        $char: "\";
    }
    @if $filename == external {
        $char: "\";
    }
    @if $filename == facebook-messenger {
        $char: "\";
    }
    @if $filename == facebook {
        $char: "\";
    }
    @if $filename == flickr {
        $char: "\";
    }
    @if $filename == foursquare {
        $char: "\";
    }
    @if $filename == generic {
        $char: "\";
    }
    @if $filename == googleplus {
        $char: "\";
    }
    @if $filename == historyBack {
        $char: "\";
    }
    @if $filename == home {
        $char: "\";
    }
    @if $filename == instagram {
        $char: "\";
    }
    @if $filename == language {
        $char: "\";
    }
    @if $filename == linkedin {
        $char: "\";
    }
    @if $filename == mail {
        $char: "\";
    }
    @if $filename == menu {
        $char: "\";
    }
    @if $filename == next {
        $char: "\";
    }
    @if $filename == overview {
        $char: "\";
    }
    @if $filename == pdf {
        $char: "\";
    }
    @if $filename == pinterest {
        $char: "\";
    }
    @if $filename == previous {
        $char: "\";
    }
    @if $filename == search {
        $char: "\";
    }
    @if $filename == twitter {
        $char: "\";
    }
    @if $filename == whatsapp {
        $char: "\";
    }
    @if $filename == xing {
        $char: "\";
    }
    @if $filename == xlsx {
        $char: "\";
    }
    @if $filename == youtube {
        $char: "\";
    }

    @return $char;
}

@mixin icon($filename, $insert: before) {
    &::#{$insert} {
        @extend %icon;
        content: icon-char($filename);
    }
}

%icon--arrowleft,
.icon--arrowleft {
    @include icon(arrowleft);
}
%icon--arrowright,
.icon--arrowright {
    @include icon(arrowright);
}
%icon--burger,
.icon--burger {
    @include icon(burger);
}
%icon--close,
.icon--close {
    @include icon(close);
}
%icon--crossReference,
.icon--crossReference {
    @include icon(crossReference);
}
%icon--external,
.icon--external {
    @include icon(external);
}
%icon--facebook-messenger,
.icon--facebook-messenger {
    @include icon(facebook-messenger);
}
%icon--facebook,
.icon--facebook {
    @include icon(facebook);
}
%icon--flickr,
.icon--flickr {
    @include icon(flickr);
}
%icon--foursquare,
.icon--foursquare {
    @include icon(foursquare);
}
%icon--generic,
.icon--generic {
    @include icon(generic);
}
%icon--googleplus,
.icon--googleplus {
    @include icon(googleplus);
}
%icon--historyBack,
.icon--historyBack {
    @include icon(historyBack);
}
%icon--home,
.icon--home {
    @include icon(home);
}
%icon--instagram,
.icon--instagram {
    @include icon(instagram);
}
%icon--language,
.icon--language {
    @include icon(language);
}
%icon--linkedin,
.icon--linkedin {
    @include icon(linkedin);
}
%icon--mail,
.icon--mail {
    @include icon(mail);
}
%icon--menu,
.icon--menu {
    @include icon(menu);
}
%icon--next,
.icon--next {
    @include icon(next);
}
%icon--overview,
.icon--overview {
    @include icon(overview);
}
%icon--pdf,
.icon--pdf {
    @include icon(pdf);
}
%icon--pinterest,
.icon--pinterest {
    @include icon(pinterest);
}
%icon--previous,
.icon--previous {
    @include icon(previous);
}
%icon--search,
.icon--search {
    @include icon(search);
}
%icon--twitter,
.icon--twitter {
    @include icon(twitter);
}
%icon--whatsapp,
.icon--whatsapp {
    @include icon(whatsapp);
}
%icon--xing,
.icon--xing {
    @include icon(xing);
}
%icon--xlsx,
.icon--xlsx {
    @include icon(xlsx);
}
%icon--youtube,
.icon--youtube {
    @include icon(youtube);
}
