.preloader--wrapper {
    display: inline-block;
    width: 1em;
    height: 1em;

    > .preloader {
        top: -1em;
        left: 3em;
    }
}

.preloader {
    display: inline-block;
    position: relative;
    transform: translateZ(0);
    animation: preloader-animation 1.1s infinite ease;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    text-indent: -9999em;
    font-size: 4px;
}

@keyframes preloader-animation {
    0%,100% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.1),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.2),
                    2.5em 0 0 0 fade-out($preloader-color, 0.2),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.2),
                    0 2.5em 0 0 fade-out($preloader-color, 0.2),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.2),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.5),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.7);
    }

    12.5% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.7),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.1),
                    2.5em 0 0 0 fade-out($preloader-color, 0.2),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.2),
                    0 2.5em 0 0 fade-out($preloader-color, 0.2),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.2),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.2),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.5);
    }

    25% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.5),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.7),
                    2.5em 0 0 0 fade-out($preloader-color, 0.1),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.2),
                    0 2.5em 0 0 fade-out($preloader-color, 0.2),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.2),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.2),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.2);
    }

    37.5% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.2),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.5),
                    2.5em 0 0 0 fade-out($preloader-color, 0.7),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.2),
                    0 2.5em 0 0 fade-out($preloader-color, 0.2),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.2),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.2),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.2);
    }

    50% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.2),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.2),
                    2.5em 0 0 0 fade-out($preloader-color, 0.5),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.7),
                    0 2.5em 0 0 fade-out($preloader-color, 0.1),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.2),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.2),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.2);
    }

    62.5% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.2),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.2),
                    2.5em 0 0 0 fade-out($preloader-color, 0.2),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.5),
                    0 2.5em 0 0 fade-out($preloader-color, 0.7),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.1),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.2),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.2);
    }

    75% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.2),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.2),
                    2.5em 0 0 0 fade-out($preloader-color, 0.2),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.2),
                    0 2.5em 0 0 fade-out($preloader-color, 0.5),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.7),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.1),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.2);
    }

    87.5% {
        box-shadow: 0 -2.6em 0 0 fade-out($preloader-color, 0.2),
                    1.8em -1.8em 0 0 fade-out($preloader-color, 0.2),
                    2.5em 0 0 0 fade-out($preloader-color, 0.2),
                    1.75em 1.75em 0 0 fade-out($preloader-color, 0.2),
                    0 2.5em 0 0 fade-out($preloader-color, 0.2),
                    -1.8em 1.8em 0 0 fade-out($preloader-color, 0.5),
                    -2.6em 0 0 0 fade-out($preloader-color, 0.7),
                    -1.8em -1.8em 0 0 fade-out($preloader-color, 0.1);
    }
}
