// Backgrounds
// ------------
@function backgrounds($theme: 'blue') {
    @return (
        pattern: 'url(/assets/img/dummy/background.png)',
        light: color('white', $theme),
        light-opacity: transparentize(color('white', $theme), 0.3),
        main: color('main', $theme),
        main-opacity: transparentize(color('main'), 0.5),
        primary: color('primary', $theme),
        primary-opacity: transparentize(color('primary', $theme), 0.3),
        primary-dark: color('primary-dark', $theme),
        primary-light: color('primary-light', $theme),
        primary-lighter1: color('primary-lighter1', $theme),
        primary-lighter2: color('primary-lighter2', $theme),
        primary-lighter3: color('primary-lighter3', $theme),
        primary-lighter4: color('primary-lighter4', $theme)
    );
}

// function to easily access background values and also validate the input
@function background($bg, $theme: 'blue') {
    $backgrounds: backgrounds($theme);

    @if map-has-key($backgrounds, $bg) {
        @return map-get($backgrounds, $bg);
    } @else {
        @error 'The given background "#{$bg}" does not exist';
    }
}

// mixin to generate all backgrounds based on the background map
@mixin backgrounds-generate() {
    // generate global backgrounds
    $backgrounds: backgrounds();

    @each $name, $value in $backgrounds {
        .bg--#{$name} {
            background: #{$value};
        }
    }

    // generate theme backgrounds
    @each $theme-name, $them-colors in $themes {
        $backgrounds: backgrounds($theme-name);

        @each $name, $value in $backgrounds {
            .theme--#{$theme-name} .bg--#{$name},
            .theme--#{$theme-name}.bg--#{$name} {
                background: #{$value};
            }
        }
    }
}

// mixin to determine if the current element is inside, or itself, styled by a generated background class
@mixin on-background($bg) {
    $backgrounds: backgrounds();

    @if map-has-key($backgrounds, $bg) {
        &.bg--#{$bg},
        .bg--#{$bg} & {
            @content;
        }
    } @else {
        @error 'The given background "#{$bg}" does not exist';
    }
}
